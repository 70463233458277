import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
      <Route  path='/'  element={<Home />}> </Route>

      </Routes>
      <Footer />
    </Router>
    </div>
  );
}


export default App; 

import React, { Children } from 'react';
import './Button.css'

function Button({children, buttonStyle, onClick}){
    
    const checkStyle = () =>{ 
        if(buttonStyle === 'discord'){
          console.log('discord color')
          return '#4850BE';
        }
        else if(buttonStyle === 'twitter'){
          console.log('twiiter color')
          return '#4A9AE9';
        }
    }

    const checkTwitter = () =>{
      console.log("check got called");
        if(buttonStyle === 'twitter')
        return true;
    }

    return (
      <div className='button-container'>
                <button
                className={`btn`}
                onClick={onClick}
                >
                {children}
                </button>
      </div>
    );
}

export default Button;

import React, {useState} from 'react';
import './MerchImg.css';
import ashok  from '../assets/ashokpfp.jpeg';
import Modal from './Modal';
import nash  from '../assets/nashpfp.jpeg';
import brandon  from '../assets/brandonpfp.jpeg';
import manga  from '../assets/manga.jpeg';
import tee1 from '../assets/tee1.png';
import crew from '../assets/crewneckW.png';
import set from '../assets/set.png';
import clothing  from '../assets/hoodie.png';






function MerchImg({imgName, title}){


    const [isOpen, setIsOpen] = useState(false);

    const checkImg = () =>{
        if(imgName === 'clothing1'){
            return clothing;
        }
        else if(imgName === 'tee1'){
            return tee1;
        }
        else if(imgName === 'crew1'){
            return crew;
        }
        else if(imgName === 'set'){
            return set;
        }

    }

    return (
            <div className='merch-container-bg'>
            <div className='merch-img-container-outter' >
                <img className='merch-img' src={checkImg()}></img>
            </div>
            <p className='merch-title'>SHINIGAMI THE DEATH CLUB</p>
            <p className='merch-name'>{title}</p>
            

            </div>

    );
}

export default MerchImg;
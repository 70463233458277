import React from 'react';
import './Footer.css';
import logo from  '../assets/logonew.png';


function Footer(){
    return(
        <div className='footer-container'>
            <div className='footer-contents'>
            <a className="navbar-icon__button" href='https://twitter.com/ShinigamiClub' >
                                <icon className='footer-twitter-link'>
                                    <i class="fab fa-twitter"></i>
                                </icon>
            </a>
            <div className="footer-logo-img-wrapper">
                    <img className="footer-logo-img" src={logo} ></img>
            </div>
            <a className="navbar-icon__button"  href='https://discord.com/invite/z4g8fDgKNh'>
                                <icon className='social-links'>
                                <i class="fab fa-discord"></i>
                                </icon>
            </a>
            </div>
                   
        </div>
    );
}

export default Footer;
import React, {useState} from 'react';
import './Modal.css';
import ashok  from '../assets/ashokpfp.jpeg';


function Modal({closeModal, isOpen, imgName}){
    if(isOpen === false){
        console.log('isOpen is ', isOpen)
        return null;
    }    

    else{
        console.log(imgName);
        {console.log('isOpen is', isOpen)}

    return (
            
            <div className='modal-bgcontainer' onClick={closeModal}>
                <div className='modal-buttons-container'>
                    <div className='modal-container'>
                        <div className='modal-img-wrapper'>
                        <img className='modal-img' src={imgName} ></img>
                        </div>
                    </div>
                </div>
            </div>
    );
    }

}

export default Modal;
import React from 'react';
import './Title.css';
import logo from  '../assets/footerlogo.png';


function Title({title, divider}){
    
    return(
        <div className='title-container'>
        <div className={ divider ? 'divider' : ''}></div>
        <h1 className='header-title'>{title}</h1>
        </div>
    );
}

export default Title;
import React, {useState} from "react";
import './Navbar.css';
import newlogo from  '../assets/logonew.png';
import { HashLink as Link } from 'react-router-hash-link';


function Navbar(){
    
    const [isActive, setIsActive] = useState(false);
    const handleClick = () =>{setIsActive(!isActive)};
    const closeMobileMenu = () => setIsActive(false);



    return(
        <div className="navbar-container">
                <div className="logo-img-wrapper-mobile-bg">
                    <div className="logo-img-wrapper-mobile">
                        <img className="logo-img" src={newlogo} ></img>
                    </div>
                </div>
            <ul  className={ isActive ? "navbar-items active" : "navbar-items" }>

                <li className="navbar-item">
                <Link  style={{ textDecoration: 'none' }} smooth to='#team' onClick={closeMobileMenu}>
                    <div className="link-container">
                    <p className="navbar-link">
                        TEAM
                    </p>
                    </div>
                    </Link>

                </li>


                <li className="navbar-item">
                <Link  style={{ textDecoration: 'none' }} smooth to='#road' onClick={closeMobileMenu}>

                <div className="link-container">
                    <p className="navbar-link">
                        ROADMAP
                    </p>
                    </div>
                    </Link>

                </li>

                <div className="logo-img-wrapper">
                    <img className="logo-img" src={newlogo} ></img>
                </div>

                <li className="navbar-item">
                <Link  style={{ textDecoration: 'none' }} smooth to='#merch' onClick={closeMobileMenu}>

                <div className="link-container">
                    <p className="navbar-link">
                        MERCH
                    </p>
                    </div>
                    </Link>

                </li>

                <li className="navbar-item">
                <Link  style={{ textDecoration: 'none' }} smooth to='#lore' onClick={closeMobileMenu}>

                <div className="link-container">
                    <p className="navbar-link">
                        LORE
                    </p>
                    </div>
                    </Link>

                </li>
            </ul>

            <div className='menu-icon' onClick={handleClick}>
            
                    <i className={isActive ? 'fas fa-times' : 'fas fa-bars'} ></i>
            </div> 
        </div>
    );
}

export default Navbar;
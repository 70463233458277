import React, {useState} from 'react';
import './MangaImg.css';
import ashok  from '../assets/ashokpfp.jpeg';
import Modal from './Modal';
import nash  from '../assets/nashpfp.jpeg';
import brandon  from '../assets/brandonpfp.jpeg';
import manga  from '../assets/manga.jpeg';
import mangaf  from '../assets/magf.jpeg';
import mangab from '../assets/magb.jpeg';






function MangaImg({onClick, imgName}){


    const [isOpen, setIsOpen] = useState(false);

    function  openModal(){
        setIsOpen(prev => !prev);
    }

    function closeModal(){
        console.log('ISOPEN IS FALSE!')
        setIsOpen(false);
    }


    

    const checkImg = () =>{
        if(imgName === 'manga1'){
            return mangaf;
        }
        else if(imgName === 'manga2'){
            return mangab;
        }
        else if(imgName === 'manga3'){
            return manga;
        }


    }

    return (
            <>
            <div className='manga-img-container-outter'  >
                <img className='modal-container-img' src={checkImg()}></img>
            </div>
            <Modal imgName={checkImg()} closeModal={() => setIsOpen(false)}  isOpen={isOpen}/>

            </>

    );
}

export default MangaImg;
import React, {useState} from 'react';
import Button from '../components/Button';
import './Home.css';
import bitcoin from '../assets/bitcoinmag.png';
import ftx from '../assets/ftx.png'
import ashok  from '../assets/ashokpfp.jpeg';
import connor  from '../assets/connorpfp.jpeg';
import brandon  from '../assets/brandonpfp.jpeg';
import nash  from '../assets/nashpfp.jpeg';
import franny  from '../assets/frannypfp.jpeg';
import roadmap  from '../assets/roadmp.png';
import mangaf  from '../assets/magf.jpeg';
import mangab from '../assets/magb.jpeg';
import MangaImg from '../components/MangaImg';
import Modal from '../components/Modal';
import MerchImg from '../components/MerchImg';
import Title from '../components/Title';
import "../components/FAQ";
import FAQ from '../components/FAQ';
import Navbar from '../components/Navbar';






function Home(){

    /*  ROADMAP

    10% Inital 10,000 PFP drop of Shinigami
    20% Release Merch for the Shinigami
    30% 3D Printed Mask for Shinigami
    40%
    50% Volume #1 of Manga
    60%



    */

    const [FAQs, setFAQs] = useState([

        
        
        {
            question: "When is the Mint Date?",
            answer: "TBD",
            open: false,

        },

        {
            question: "What chain will the NFTs be minted on?",
            answer: "The NFTs are going to be on Ethereum",
            open: false,

        },

        {
            question: "What is the mint price for the NFTs",
            answer: "The mint price for public sale is 0.09 ETH ",
            open: false,

        },

        {
            question: "What is VCU Blockchain? ",
            answer: "It is a university club that hosts events, guest speakers and educates people on Blockchain technology",
            open: false,

        },

    ]);

    const toggleFAQ = (index) =>{
        setFAQs(FAQs.map((faq, i)=>{
            if(i === index){
                faq.open = !faq.open;
            }
            else{
                faq.open = false;
            }

            return faq;
        }))

    }


 


    return ( 
       <div className='page-container'>

            <div className='intro-container'>
                <Navbar />
                <div className='intro-contents'>
                    <h1 className='logo-title'>SHINIGAMI</h1>

                    <div className='social-links-container'>

                        <div className='twitter-button-container'>
                                <a href='https://twitter.com/ShinigamiClub'>
                                    <Button buttonStyle='twitter'>
                                        Twitter
                                    </Button>             
                                </a>   
                        </div>

                        <div className='twitter-button-container'>
                                <a href='https://discord.com/invite/z4g8fDgKNh'>
                                    <Button buttonStyle='discord'>
                                        Discord
                                    </Button>             
                                </a>
                        </div>
                    </div>
                </div>
            </div>    
        

            <Title  title="MANGA" divider={true}  />
            <div id='lore' className='lore-container'>
                <p className='lore-overview'>Soul eaters shroud the realm in darkness and desolation, threatening to end the rule of the living and begin a new age, an age of death and destruction. Humanity’s last hope lies in the hands of one man’ bravery to face the end of the world as they know it. Along the path of righteousness, he meets people willing to join the cause and give their lives if need be. Together they face almost certain death.</p>
                <div className='manga-img-container'>
                    <MangaImg   imgName='manga1' />
                    <MangaImg   imgName='manga2'/>
                </div>
            </div>

            <div className='road-conatiner'>
                    <div className='bg'></div>
                    <div className='blur'></div>
                    
                    <div className='roadmap-container'>
                        <h1 id='road' className='teams-title'>ROADMAP</h1>   
                            <div className='roadmap-img-wrapper'>
                                <img className='roadmap-img' src={roadmap}></img>
                            </div>
                        

                    </div>

            </div>



            
            <div id='merch' className='manga-section-container'>
            <Title title="CLOTHING"/>
                <div className='merch-img-container'>
                    <MerchImg imgName='clothing1' title='BLACK HOODIE' />
                    <MerchImg imgName='tee1'  title='BLACK TEE' />
                    <MerchImg imgName='crew1' title='WHITE CREWNECK' />

                    
                </div>
            </div>

            <Title title="FAQ" divider={true}  />
            <div className='faq-bg'>
            <div className='faqs-container'>
                {FAQs.map((faq, i) =>(
                   <div>
                   <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ}/>
                   </div>
                ))};
            </div>
            </div>
            

        </div>

    

            
        
      

    );
};

export default Home;